import {
  styled,
  Typography,
  Box,
  //@ts-ignore
} from "@enerbit/base";
import CardPath from "./components/CardPath";
import { pathOptions } from "./const/path";
import CalendarComponent from "./components/Calendar";

const Home = (): JSX.Element => {
  return (
    <Box sx={{ width: "90%", m: "auto" }}>
      <Container>
        <Typography
          color="primary"
          variant="h1"
          sx={{ fontSize: "34px", fontWeight: "bold", mt: 10 }}
        >
          Ópera
        </Typography>

        <CardContainer>
          {pathOptions?.map((card, index) => (
            <CardPath card={card} key={index} />
          ))}
        </CardContainer>
        <CalendarComponent />
      </Container>
    </Box>
  );
};

export default Home;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  margin-left: 70px;
  justify-content: center;
  width: calc(100% - 70px);
`;

const CardContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;
