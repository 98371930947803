//@ts-ignore
import { api } from "@enerbit/base";
import moment from "moment";

const formatSince = moment().startOf("month").format("YYYY-MM-DD");
const formatUntil = moment().endOf("month").format("YYYY-MM-DD");

const formatterResponse = (data) => {
  const formaterJson = data.items.map((item) => ({
    id: item.id,
    start: moment(`${item.from_}`).subtract(5, "h").toDate(),
    color: item.stage_name === "Done" ? "#12B76A" : "#F79009",
    end: moment(`${item.to}`).subtract(5, "h").toDate(),
    title: `${item.title}\nOperador: ${item.operators}`,
    operator: item.operators,
    stage_name: item.stage_name,
  }));
  return formaterJson;
};

export const getOrdersByEmailOrLocation = async (
  query: string,
  value: string,
  since = formatSince,
  until = formatUntil
) => {
  const url = `field-service/work-orders-calendar/?${query}=${value.toLowerCase()}&since=${since}&until=${until}&page=0&size=200`;
  const { data } = await api.get(url);
  if (data.items.length === 0) {
    throw new Error();
  }
  return formatterResponse(data);
};

export const getOrderByLocationByEmailAndLocation = async (
  email,
  location,
  since = formatSince,
  until = formatUntil
) => {
  const url = `field-service/work-orders-calendar/?operator_email=${email}&location=${location.toLowerCase()}&since=${since}&until=${until}&page=0&size=200`;
  const { data } = await api.get(url);
  if (data.items.length === 0) {
    throw new Error();
  }
  return formatterResponse(data);
};
