import { Box, Typography, styled } from "@enerbit/base";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

moment.locale("es");

const CustomCalendarToolbar = ({ date, view, onView, onNavigate }) => {
  const nextDate = useMemo(() => {
    if (view === "day") {
      return `${moment(date).add(1, "day").date()} ${moment(date)
        .add(1, "day")
        .format("MMMM")}`;
    } else {
      return `${moment(date).add(1, "month").format("MMMM")}`;
    }
  }, [date, view]);

  const previousDate = useMemo(() => {
    if (view === "day") {
      return `${moment(date).subtract(1, "day").date()} ${moment(date)
        .add(1, "day")
        .format("MMMM")}`;
    } else {
      return `${moment(date).subtract(1, "month").format("MMMM")}`;
    }
  }, [date, view]);

  const navToPrevious = () => {
    const newDate = moment(date).subtract(1, view).toDate();
    onNavigate("day", newDate);
  };

  const navToNext = () => {
    const newDate = moment(date).add(1, view).toDate();
    onNavigate("day", newDate);
  };

  return (
    <Box>
      <MonthContainer>
        <Typography
          onClick={() => onView("month")}
          sx={{
            fontSize: "22px",
            cursor: "pointer",
            fontWeight: 700,
            color: view === "month" ? "#ED7004" : "#344054",
          }}>
          Mes
        </Typography>
        <Typography
          onClick={() => onView("day")}
          sx={{
            fontSize: "22px",
            cursor: "pointer",
            fontWeight: 700,
            color: view === "day" ? "#ED7004" : "#344054",
          }}>
          Día
        </Typography>
      </MonthContainer>
      <DateContainer>
        <Typography>
          <strong>{`${moment(date).date()} ${moment(date).format("MMMM")}`}</strong>
          {` ${moment(date).year()}`}
        </Typography>
      </DateContainer>
      <NavigationContainer>
        <Typography
          onClick={() => navToPrevious()}
          color={"primary"}
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "18px",
            cursor: "pointer",
            fontWeight: 700,
          }}>
          <ArrowBackIosIcon />
          {previousDate}
        </Typography>
        <Typography
          onClick={() => navToNext()}
          color={"primary"}
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "18px",
            cursor: "pointer",
            fontWeight: 700,
          }}>
          {nextDate}
          <ArrowForwardIosIcon />
        </Typography>
      </NavigationContainer>
    </Box>
  );
};

export default CustomCalendarToolbar;

const MonthContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 35px;
`;

const DateContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const NavigationContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
