//@ts-ignore
import { styled, Typography, Box, moment } from "@enerbit/base";

const ModalDaylyOrder = ({ closeModal, singleOrder }) => {
  const { title, operator, end, start, stage_name } = singleOrder;

  const word = "Operador";
  const wordIndex = title.indexOf(word);
  const activity = title.substring(0, wordIndex);

  const getInitialHour = moment(start).add(5, "hours").format("HH:mm");
  const getFinalHour = moment(end).add(5, "hours").format("HH:mm");
  return (
    <ModalMask>
      <Modal>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            mb: 1,
          }}
        >
          <CloseIcon onClick={closeModal}>X</CloseIcon>
        </Box>
        <Typography
          color="primary"
          sx={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {activity}
        </Typography>
        <Typography color="primary" sx={{ fontSize: "20px" }}>
          Operador:{operator}
        </Typography>
        <Typography color="primary" sx={{ fontSize: "20px" }}>
          Estado de la orden:
          {stage_name === "Done" ? "Completada" : "Pendiente"}
        </Typography>

        <Typography color="#667085" sx={{ mt: 2, mb: 3 }}>
          Fecha:{moment(start).format("DD/MM/YYYY")} {getInitialHour} a{" "}
          {getFinalHour}
        </Typography>
      </Modal>
    </ModalMask>
  );
};

const ModalMask = styled("label")`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
`;

const CloseIcon = styled("label")`
  color: #53358e;
  cursor: pointer;
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid #53358e;
  border-radius: 50%;
`;

const Modal = styled("label")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 32px;
  position: absolute;
  width: 389px;
  min-height: 210px;
  max-height: 300px;
  line-height: 0.5;
  background: #ffffff;
  border-radius: 16px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;

export default ModalDaylyOrder;
