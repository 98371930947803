//@ts-ignore
import { Typography, Box, CustomAlert, styled } from "@enerbit/base";
import { useEffect, useState } from "react";
import { Calendar, View, momentLocalizer } from "react-big-calendar";
import ModalDaylyOrder from "./ModalDaylyOrder";
import Search from "./Search";
import moment from "moment";
import {
  getOrderByLocationByEmailAndLocation,
  getOrdersByEmailOrLocation,
} from "../../../services/getOrders";
import Bombi from "../../../assets/img/Bombi-AE.gif";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomCalendarToolbar from "./CustomCalendarToolbar";
import "../../../styles.css";

moment.locale("es");
const localizer = momentLocalizer(moment);

const CalendarComponent = () => {
  const [operatorEmail, setOperatorEmail] = useState<string>("");
  const [stateSelected, setStateSelected] = useState<string>("");
  const [orders, setOrders] = useState<any>();
  const [singleOrder, setSingleOrder] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [severityAlert, setSeverityAlert] = useState<any>("");
  const [textAlert, setTextAlert] = useState<any>("");
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [monthsFetched, setMonthsFetched] = useState<string[]>([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  // const handleEventClick = (id) => {
  //   //@ts-ignore
  //   const dailyOrder = orders?.find((order) => order.id === id);
  //   setSingleOrder(dailyOrder);
  //   setIsOpenModal(true);
  // };

  const handleEventClick = (event) => {
    setSingleOrder(event);
    setIsOpenModal(true);
  };

  const closeAlert = (): void => {
    setSeverityAlert("");
    setTextAlert("");
  };

  const getOrders = async (since, until, month, year) => {
    try {
      setIsLoadingOrders(true);
      setSeverityAlert("");
      setTextAlert("");
      if (operatorEmail && !stateSelected) {
        let res = await getOrdersByEmailOrLocation("operator_email", operatorEmail, since, until);
        setOrders([...orders, ...res]);
        setMonthsFetched([...monthsFetched, `${month}-${year}`]);
      } else if (!operatorEmail && stateSelected) {
        let res = await getOrdersByEmailOrLocation("location", stateSelected, since, until);
        setOrders([...orders, ...res]);
        setMonthsFetched([...monthsFetched, `${month}-${year}`]);
      } else if (operatorEmail && stateSelected) {
        let res = await getOrderByLocationByEmailAndLocation(
          operatorEmail,
          stateSelected,
          since,
          until
        );
        setOrders([...orders, ...res]);
        setMonthsFetched([...monthsFetched, `${month}-${year}`]);
      }
    } catch (error) {
      setSeverityAlert("error");
      setTextAlert("No se encontraron órdenes");
      return error;
    } finally {
      setIsLoadingOrders(false);
    }
  };

  const closeModal = (): void => {
    setIsOpenModal(false);
  };

  const handleNavigate = (date: Date) => {
    console.log("date: ", date);
    const month = moment(date).month();
    const year = moment(date).year();

    if (!monthsFetched.includes(`${month}-${year}`)) {
      const since = moment().year(year).month(month).startOf("month").format("YYYY-MM-DD");
      const until = moment().year(year).month(month).endOf("month").format("YYYY-MM-DD");

      getOrders(since, until, month, year);
    }
    setCurrentDate(date);
  };

  const eventStyleGetter = (event) => {
    const backgroundColor = event.color;
    const style = {
      backgroundColor,
      borderRadius: "8px",
      border: "none",
    };
    return {
      style,
    };
  };

  const dayStyleGetter = (date) => {
    const style = {};
    const today = new Date();

    if (moment(date).isSame(today, "day")) {
      style["backgroundColor"] = "transparent"; // Establece el fondo transparente para el día actual
    }

    return { style };
  };

  const handleRangeChange = (view: View) => {
    setSeverityAlert("");
    setTextAlert("");

    const month = moment(currentDate).month();
    const year = moment(currentDate).year();
    if (monthsFetched?.includes(`${month}-${year}`)) {
      return;
    }
    const since = moment().year(year).month(month).startOf("month").format("YYYY-MM-DD");
    const until = moment().year(year).month(month).endOf("month").format("YYYY-MM-DD");
    if (view === "month") {
      getOrders(since, until, month, year);
    }
  };

  useEffect(() => {
    console.log("month feched: ", monthsFetched);
  }, [monthsFetched]);

  return (
    <>
      <Typography color='primary' sx={{ fontSize: "34px", fontWeight: "bold", mt: 3, mb: 2 }}>
        Órdenes asignadas
      </Typography>{" "}
      <Typography color='#344054' sx={{ fontSize: "24px", mb: 3 }}>
        Puedes buscar por correo del operador o región.
      </Typography>
      <Search
        setOperatorEmail={setOperatorEmail}
        setStateSelected={setStateSelected}
        operatorEmail={operatorEmail}
        stateSelected={stateSelected}
        setOrders={setOrders}
      />
      {severityAlert && (
        <Box sx={{ my: 1 }}>
          <CustomAlert severity={severityAlert} text={textAlert} onClose={closeAlert} />
        </Box>
      )}
      {isLoadingOrders && (
        <ModalMask>
          <BombiContainer>
            <img src={Bombi} alt='bombi' width='250px' />
          </BombiContainer>
        </ModalMask>
      )}
      {orders && (
        <Box sx={{ my: 2, whiteSpace: "pre-wrap" }}>
          <Box sx={{ display: "flex" }}>
            <ColorOrder color='#12B76A' /> Completada
          </Box>
          <Box sx={{ display: "flex" }}>
            <ColorOrder color='#F79009' /> Pendiente
          </Box>
          <Box my={3}>
            <Calendar
              localizer={localizer}
              defaultDate={currentDate}
              defaultView={"day"}
              events={orders}
              style={{ height: "800px" }}
              components={{ toolbar: CustomCalendarToolbar }}
              onNavigate={handleNavigate}
              eventPropGetter={eventStyleGetter}
              dayPropGetter={(date) => dayStyleGetter(date)}
              dayLayoutAlgorithm={"no-overlap"}
              onView={handleRangeChange}
              onSelectEvent={handleEventClick}
            />
          </Box>
        </Box>
      )}
      {isOpenModal && <ModalDaylyOrder closeModal={closeModal} singleOrder={singleOrder} />}
    </>
  );
};

export default CalendarComponent;

const ModalMask = styled("label")`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
`;

const BombiContainer = styled("label")`
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;

const ColorOrder = styled("div")(({ color }) => ({
  height: "15px",
  width: "15px",
  background: color,
  borderRadius: "3px",
}));
