export const pathOptions = [
  {
    icon: "PeopleAltIcon",
    text: "Crear, actualizar y consultar",
    path: "#/operators",
    footerText: "Operadores y empresas",
  },
  {
    icon: "ListOutlinedIcon",
    text: "Crear, actualizar y consultar",
    path: "#/records",
    footerText: "Gestión campo",
  },
  {
    icon: "AddCircleOutlineIcon",
    text: "Crear, actualizar y consultar",
    path: "#/orders",
    footerText: "Órdenes de servicios",
  },
  {
    icon: "PictureAsPdfOutlinedIcon",
    text: "Documentación y hoja de vida",
    path: "#/documents",
    footerText: "Biblioteca de punto de medida",
  },
];
