import { ThemeConfig, NavSidebar, initEnviroment } from "@enerbit/base";
import Home from "./pages/home/Home";
import "./styles.css";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
  geopoliticsBaseUrl: process.env.REACT_APP_BASE_GEOPOLITICS_URL
});

export default function Root() {
  return (
    <section>
      <ThemeConfig>
        <NavSidebar />
        <Home />
      </ThemeConfig>
    </section>
  );
}
