import {
  TextField,
  Autocomplete,
  styled,
  InputAdornment,
  SearchIcon,
  FormControl,
  LoadingButton,
  Box,
  CustomAlert,
  //@ts-ignore
} from "@enerbit/base";
import { useEffect, useState } from "react";
import {
  getOrderByLocationByEmailAndLocation,
  getOrdersByEmailOrLocation,
} from "../../../services/getOrders";
import { getStatesByCounntryId } from "../../../services/getStates";

type Props = {
  setOperatorEmail: Function;
  setStateSelected: Function;
  operatorEmail: string;
  setOrders: any;
  stateSelected: any;
};

const Search = ({
  setStateSelected,
  setOperatorEmail,
  operatorEmail,
  setOrders,
  stateSelected,
}: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [listStates, setListStates] = useState<any>([]);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [severityAlert, setSeverityAlert] = useState<any>("");
  const [textAlert, setTextAlert] = useState("");

  const closeAlert = (): void => {
    setSeverityAlert("");
    setTextAlert("");
  };

  const getOrders = async () => {
    try {
      setIsLoadingOrders(true);
      setSeverityAlert("");
      setTextAlert("");
      if (operatorEmail && !stateSelected) {
        let res = await getOrdersByEmailOrLocation(
          "operator_email",
          operatorEmail
        );
        setOrders(res);
      } else if (!operatorEmail && stateSelected) {
        let res = await getOrdersByEmailOrLocation("location", stateSelected);
        setOrders(res);
      } else if (operatorEmail && stateSelected) {
        let res = await getOrderByLocationByEmailAndLocation(
          operatorEmail,
          stateSelected
        );
        setOrders(res);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setSeverityAlert("error");
        setTextAlert("El operador ingresado no existe");
        return;
      }
      setSeverityAlert("error");
      setTextAlert("No se encontraron órdenes");
      return error;
    } finally {
      setIsLoadingOrders(false);
    }
  };

  useEffect(() => {
    try {
      getStatesByCounntryId().then(setListStates);
    } catch (error) {
      return error;
    }
  }, []);
  return (
    <>
      <FormContainer grid_columns={3}>
        <FormControl>
          <CustomLabel>Región</CustomLabel>
          <Autocomplete
            id="disabled-options-demo"
            options={listStates.length > 0 ? listStates : []}
            getOptionLabel={(option) => option || inputValue}
            isOptionEqualToValue={(option, value) => option === value}
            value={stateSelected}
            fullWidth
            inputValue={inputValue}
            onInputChange={(_, value) => setInputValue(value)}
            onChange={(_, newValue) => setStateSelected(newValue)}
            renderInput={(params) => (
              <TextField
                sx={{ "& fieldset": { borderRadius: "12px" } }}
                {...params}
              />
            )}
          />
        </FormControl>
        <FormControl>
          <CustomLabel>Correo electrónico</CustomLabel>
          <TextField
            sx={{ "& fieldset": { borderRadius: "12px" } }}
            onChange={({ target }) => setOperatorEmail(target.value)}
            InputProps={{
              endAdornment: (
                //@ts-ignore
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Box sx={{ mt: 3 }}>
          <LoadingButton
            variant="contained"
            color="secondary"
            fullWidth
            size="medium"
            sx={{ height: "55px" }}
            onClick={getOrders}
            loading={isLoadingOrders}
          >
            Buscar
          </LoadingButton>
        </Box>
      </FormContainer>
      {severityAlert && (
        <Box sx={{ my: 2 }}>
          <CustomAlert
            severity={severityAlert}
            text={textAlert}
            onClose={closeAlert}
          />
        </Box>
      )}
    </>
  );
};

const FormContainer = styled("form")(
  ({ grid_columns }: { grid_columns: number }) => ({
    display: "grid",
    gridTemplateColumns: `repeat(${grid_columns}, 1fr)`,
    columnGap: "1rem",
    "@media (max-width: 768px)": {
      display: "block",
    },
  })
);

const CustomLabel = styled("label")`
  color: " #34454";
  margin-bottom: 5px;
`;

export default Search;
