//@ts-ignore

import { geopoliticsApi } from "@enerbit/base";

export const getStatesByCounntryId = async (): Promise<string[]> => {
  try {
    const { data } = await geopoliticsApi.get(
      "/states/?country_code=co&page=1&size=50"
    );
    const listState = data.items.map((state) => state.name);
    return listState;
  } catch (error) {
    return error;
  }
};
